/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { SUBSCRIPTION_REFRESH_PERIOD } from "../../../Config";
//a space is considered a "safe" separator, as it should not occur in receiver names
const RECEIVER_SALT_SEPARATOR = " ";
const RECEIVER_SALT = "refreshSupported";
export default class ReceiverObserverSubscription {
    constructor(clientProvider, document, handler, timeoutProvider = () => SUBSCRIPTION_REFRESH_PERIOD) {
        var _a, _b;
        this.clientProvider = clientProvider;
        this.document = document;
        this.handler = handler;
        this.timeoutProvider = timeoutProvider;
        this.subscriptions = new Map();
        this.subscriptionRenewals = new Map();
        this.operationName = (_b = (_a = document.definitions.find((def) => def.kind === "OperationDefinition")) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.value;
        console.log("Created ReceiverObserverSubscription for " + this.operationName);
    }
    get knownReceivers() {
        return [...this.subscriptions.keys()];
    }
    terminateSubscriptions() {
        this.knownReceivers.forEach((receiver) => this.removeSubscription(receiver));
        this.subscriptions.clear();
    }
    onReceiversChanged(newReceivers) {
        this.knownReceivers
            .filter((oldReceiver) => !newReceivers.includes(oldReceiver))
            .forEach((receiver) => this.removeSubscription(receiver));
        newReceivers
            .filter((receiver) => !this.subscriptions.has(receiver))
            .forEach((receiver) => this.subscribe(receiver));
    }
    subscribe(receiver) {
        if (this.subscriptions.has(receiver)) {
            return;
        }
        console.log(`${this.operationName} subscribing to ${receiver}`);
        const client = this.clientProvider();
        const subscription = client
            .subscribe(this.document, {
            receiver: `${receiver}${RECEIVER_SALT_SEPARATOR}${RECEIVER_SALT}`,
        })
            .subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.trace("Socket closed, resubscribing");
                    this.reSubscribe(receiver);
                }
                else if (error.message === "Connection failed: WebSocket is not defined") {
                    console.error("Received subscription error", error);
                }
            },
            next: (update) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    this.handler((_a = update.data) !== null && _a !== void 0 ? _a : undefined);
                }
                catch (err) {
                    console.error("subscription handler leaks errors, unsubscribing handler", err);
                    this.removeSubscription(receiver);
                }
            }),
        });
        this.subscriptions.set(receiver, subscription);
        this.queueSubscriptionRenewal(receiver);
    }
    removeSubscription(receiver) {
        var _a;
        try {
            (_a = this.subscriptions.get(receiver)) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            this.subscriptions.delete(receiver);
        }
        catch (err) {
            console.error("Failed to unsubscribe from receiver", err);
        }
        finally {
            const timeoutRef = this.subscriptionRenewals.get(receiver);
            if (timeoutRef != null)
                clearTimeout(timeoutRef);
            this.subscriptionRenewals.delete(receiver);
        }
    }
    reSubscribe(receiver) {
        this.removeSubscription(receiver);
        this.subscribe(receiver);
    }
    queueSubscriptionRenewal(receiver) {
        const renewalTimeout = this.subscriptionRenewals.get(receiver);
        if (renewalTimeout != null) {
            clearTimeout(renewalTimeout);
            this.subscriptionRenewals.delete(receiver);
        }
        const timeout = this.timeoutProvider();
        const timeoutRef = setTimeout(() => {
            this.reSubscribe(receiver);
        }, timeout);
        this.subscriptionRenewals.set(receiver, timeoutRef);
    }
}
